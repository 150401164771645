import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppScanBlockedReasonValues,
  MobileAppReleaseTypeValues,
  MobileAppSubscriptionValues,
} from '@dt/graphql-support/enums';
import { CardWithStatusIndicator, ScanBadge } from '@dt/material-components';
import { palette } from '@dt/theme';
import Badge from '../internal/Badge';
import IconLabel from './../IconLabel';
import Image from './../Image';
import SubscriptionBadge from './SubscriptionBadge';
import ENTERPRISE_ICON_URL from './images/enterprise.svg';
import PRE_PROD_ICON_URL from './images/pre-prod.svg';
// eslint-disable-next-line
import MISSING_ICON_IMAGE_URL from './images/question-mark.svg';
// eslint-disable-next-line
import ERROR_ICON_IMAGE_URL from './images/question-mark.svg';
// TODO: check idk why we have these 2 same imports on the lines above.

const ICON_SIZE = 64;

const useStyles = makeStyles({
  badgeHolder: {
    display: 'flex',
  },

  card: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },

  container: {
    minWidth: 500,
  },

  iconSubscriptionBadge: {
    marginTop: -13,
    width: '100%',
  },

  iconUrl: {
    '& a': {
      display: 'block',
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    '& img': {
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    height: ICON_SIZE,
    width: ICON_SIZE,
  },

  left: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: 250,
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },

  nameHeader: {
    margin: 0,
    marginBottom: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  right: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: 50,
    flexGrow: 4,
    flexShrink: 4,
    justifyContent: 'flex-end',
    marginRight: 5,
  },

  scanStatus: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
  },

  subTitle: {
    display: 'flex',
    maxWidth: 170,
  },

  title: {
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 10,
  },

  version: { fontSize: 12 },
});

function AppSummaryCard(props) {
  const {
    id,
    name,
    platform,
    app_protection_percent,
    subscription,
    most_recent_scan,
    blocked_scan_reason,
    integrations,

    metadataCount,

    subscriptionFormat,
    scanBlockedReasonFormat,

    detailsRenderer,
    onIconClick,
    onScoreClick,
  } = props;
  const classes = useStyles();

  const iconSource =
    props.release_type &&
    (props.release_type === MobileAppReleaseTypeValues.PRE_PROD ||
      props.release_type === MobileAppReleaseTypeValues.ENTERPRISE)
      ? props.release_type === MobileAppReleaseTypeValues.PRE_PROD
        ? PRE_PROD_ICON_URL
        : ENTERPRISE_ICON_URL
      : props.icon_url || MISSING_ICON_IMAGE_URL;

  return (
    <div className={classes.container}>
      <CardWithStatusIndicator status={`5px solid ${palette.brand}`}>
        <div className={classes.card}>
          {/* Left */}
          <div className={classes.left}>
            {/* Icon */}
            <div
              className={classes.iconUrl}
              onClick={() => onIconClick(id)}
              style={onIconClick ? { cursor: 'pointer' } : {}}
            >
              <div style={{ height: '100%', width: '100%' }}>
                <Image
                  alt={`${name} icon`}
                  onFailSrc={ERROR_ICON_IMAGE_URL}
                  src={iconSource}
                  style={{ maxHeight: undefined, maxWidth: undefined }}
                />
              </div>

              {/* Subscription */}
              <div className={classes.iconSubscriptionBadge}>
                <SubscriptionBadge
                  fill={true}
                  format={subscriptionFormat}
                  radius={0}
                  size={'small'}
                  type={subscription || MobileAppSubscriptionValues.NO_SUBSCRIPTION}
                />
              </div>
            </div>

            <div className={classes.title}>
              {/* Name */}
              <h4 className={classes.nameHeader}>
                {/* Platform + Name */}
                <IconLabel platform={platform}>
                  <span>
                    {name}
                    <sup className={classes.version}>
                      {most_recent_scan && ` ${(most_recent_scan.app_version || '').toString()}`}
                    </sup>
                  </span>
                </IconLabel>
              </h4>

              <div className={classes.subTitle}>
                {/* Scan Status */}
                <div className={classes.scanStatus}>
                  <ScanBadge
                    format={scanBlockedReasonFormat}
                    includeImage={false}
                    includeLabel
                    scan={most_recent_scan}
                    type={blocked_scan_reason || AppScanBlockedReasonValues.OTHER}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right */}
          <div className={classes.right}>
            {/* Score */}
            <div style={{ flexBasis: 125, flexGrow: 0, flexShrink: 1 }}>
              {app_protection_percent && (
                <Badge
                  label={
                    <div onClick={() => onScoreClick(id)} style={onScoreClick ? { cursor: 'pointer' } : {}}>
                      {app_protection_percent} Protected
                    </div>
                  }
                />
              )}
            </div>

            <div style={{ flexGrow: 2, flexShrink: 2 }}>
              {detailsRenderer({
                id: id,
                integrations: integrations,
                metadataCount: metadataCount,
              })}
            </div>
          </div>
        </div>
      </CardWithStatusIndicator>
    </div>
  );
}

export default memo(AppSummaryCard);
