import React, { useState } from 'react';
import { Button, Snackbar, Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from '@reach/router';
const LAUNCHED_AREAS = ['api', 'mobile-secure', 'web', 'cloud'];
const useStyles = makeStyles({
    actions: {
        alignItems: 'center',
        columnGap: '4px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBlockStart: '8px',
        marginInline: '-8px',
    },
});
const V2Banner = () => {
    const [open, setOpen] = useState(true);
    const { pathname } = useLocation();
    const classes = useStyles();
    const start = pathname.split('/').filter(Boolean).shift() ?? '';
    if (pathname.includes('v2') || !LAUNCHED_AREAS.includes(start) || pathname?.includes('/share'))
        return null;
    let redirectLink;
    switch (start) {
        case 'api':
            redirectLink = '/api/v2';
            break;
        case 'web':
            redirectLink = '/web/v2';
            break;
        case 'cloud':
            redirectLink = '/cloud/v2';
            break;
        case 'mobile':
        case 'mobile-secure':
            redirectLink = '/mobile-secure/v2';
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (React.createElement(Snackbar, { anchorOrigin: { horizontal: 'center', vertical: 'top' }, onClose: handleClose, open: open },
        React.createElement(Alert, { action: React.createElement("div", { style: { display: 'none' } }), onClose: handleClose, severity: "info" },
            React.createElement(AlertTitle, null, "The Data Theorem AppSec portal has been updated"),
            "Select \u201CAwesome\u201D to be redirected now, or select \u201CWait\u201D to stay on the previous version.",
            React.createElement("div", { className: classes.actions },
                React.createElement(Button, { color: "primary", onClick: handleClose }, "Wait"),
                React.createElement(Button, { color: "primary", component: Link, to: redirectLink }, "Awesome")))));
};
export default V2Banner;
