import React, { memo } from 'react';
import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import isEqual from 'lodash/isEqual';
import { useTableFilter } from '../../hooks/use_table_filter';
import InventoryTable, { columnEnum } from './inventory_table/InventoryTable';
import InventoryTableEdit from './inventory_table_edit/InventoryTableEdit';
import InventoryTableExportButton from './inventory_table_export/InventoryTableExportButton';
import InventoryTableExportMessage from './inventory_table_export/InventoryTableExportMessage';
import useExportAssets from './inventory_table_export/use_export_assets';

const InventoryTableCardComponent = function InventoryTableCard(props) {
  const { FilterComponent, userInterfaceFilter, filters } = useTableFilter({
    assetsSearchFilterValuesQueryParams: {},
    enableBookmarking: props.enableBookmarking,
    filterEnums: props.filterEnums || [
      'filter_by_text',
      'filter_by_asset_type',
      'filter_by_discovered_via',
      'filter_by_cloud_provider',
      'filter_by_asset_group_id',
      'filter_by_is_shadow',
      'filter_by_asset_tags',
    ],
    initialFilterValues: props.initialFilterValues || null,
    readOnlyFilters: props.readOnlyFilters,
  });

  const exportHook = useExportAssets(filters);

  let columns = props.columns;
  if (filters?.filter_by_asset_tags) {
    columns = [...columns, columnEnum.asset_tag];
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid alignContent={'space-between'} container>
          <Grid item md={9} xs={12}>
            {props.loading ? (
              <Skeleton animation="wave" height={40} width={100} />
            ) : (
              <FilterComponent filterButtonName={props.filterButtonName} />
            )}
          </Grid>
          <Grid container item justifyContent={'flex-end'} md={3} xs={12}>
            {props.exportable && (
              <Box>
                <InventoryTableExportButton callExport={exportHook.caller} loading={exportHook.loading} />
              </Box>
            )}
            {props.editable && (
              <Box ml={1}>
                {props.loading ? <Skeleton animation="wave" height={40} width={100} /> : <InventoryTableEdit />}
              </Box>
            )}
            {props.renderAction ? props.renderAction : null}
          </Grid>
        </Grid>
      </Grid>
      {/* display export csv alerts,errors,stats */}
      {props.exportable && (exportHook.loading || exportHook.error) && (
        <Grid item xs={12}>
          <InventoryTableExportMessage
            completedCount={exportHook.completedCount}
            error={exportHook.error}
            loading={exportHook.loading}
            totalCount={exportHook.totalCount}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card
          style={{
            ...(props.isV2 && { borderRadius: 8, boxShadow: 'none' }),
          }}
        >
          {!props.checkboxSelection ? (
            <InventoryTable
              columns={columns}
              emptyStateVariant={
                // if user did not change the filters there is
                // no need to show 'no match' empty state
                !userInterfaceFilter || isEqual(userInterfaceFilter, props.readOnlyFilters)
                  ? 'no-asset'
                  : props.emptyStateVariant || 'no-asset'
              }
              filters={userInterfaceFilter}
              isLoading={props.loading}
              productBasePath={props.productBasePath}
            />
          ) : (
            <InventoryTable
              checkboxSelection
              checkedIds={props.checkedIds}
              columns={columns}
              emptyStateVariant={
                // if user did not change the filters there is
                // no need to show 'no match' empty state
                !userInterfaceFilter || isEqual(userInterfaceFilter, props.readOnlyFilters)
                  ? 'no-asset'
                  : props.emptyStateVariant || 'no-asset'
              }
              filters={userInterfaceFilter}
              isLoading={props.loading}
              onChangeSelection={props.onChangeSelection}
              productBasePath={props.productBasePath}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(InventoryTableCardComponent);
